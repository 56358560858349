<template>
   <AppPaginatedGrid
        :service="service"
        permission="gestaosesmt_cadastros_descricao_risco"
        newPath="/descricao-risco/new"
        editPath="/descricao-risco/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Descrição do Risco"
        tooltip=" Registro de Descrição do Risco"
        nomeTelaDoManual="descricao-risco-list"
        origemHistoricoAcao="/descricao-risco"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="descricao_risco.id" header="Cód." />
            <Column field="nome" :sortable="true" sortField="descricao_risco.nome" header="Descrição do risco" />
            <Column field="created_at" :sortable="true" sortField="descricao_risco.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreate?.name }} </small>
                </template>
            </Column>
            <Column field="updated_at" :sortable="true" sortField="descricao_risco.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userUpdate?.name }} </small>
                </template>
            </Column>
        </template>
   </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '../../services/store';

export default {
    data() {
        return {
            record: {}
        };
    },
    created() {
        this.service = new BaseService('/descricao-risco');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar descrição de risco' : 'Adicionar descrição de risco';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },

    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>
